(function () {
  'use strict';

  angular
    .module('learning.module')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard.learning.module', {
        url: '/module',
        templateUrl: 'dashboard/learning/module/module.tpl.html',
        controller: 'ModuleCreateCtrl',
        controllerAs: 'moduleCreate',
        roles: ['superadmin', 'admin', 'Teacher']
      });
  }
}());
